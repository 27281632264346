
    @import "config.scss";
 
.container {
  background-color: var(--color-primary);
  border-radius: var(--br-hard);
  padding: 15px 0;
  margin-block: 3rem;
  margin-inline: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  display: flex;
  height: 74.09px;

  ::-webkit-scrollbar {
    display: none;
  }

  .item {
    width: 30vw;
    display: flex;
    justify-content: center;
    scroll-snap-align: start;

    @include for-desktop-up {
      width: 19vw;
    }
  }
}

.slider {
  animation: 30s slides infinite linear;
  display: flex;
}

.container:hover .slider {
  @include for-desktop-up {
    animation-play-state: paused;
  }
}

@keyframes slides {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
