
    @import "config.scss";
 
.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 8rem;
  align-items: flex-start;

  .title {
    padding-inline: 1rem;
    margin-bottom: 25px;
    width: 100%;

    @include for-desktop-up {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .text {
      text-align: center;
      margin-bottom: 1rem;

      @include for-desktop-up {
        margin-block: auto;
      }
    }
  }

  .desktop {
    display: flex;
    justify-content: space-evenly;
  }
}
